const prefix = "ENVELOPE_";

export const POINT_DELETE = prefix + "POINT_DELETE";
export const POINT_ADD = prefix + "POINT_ADD";
export const POINT_EDIT_START = prefix + "POINT_EDIT_START";
export const POINT_EDIT_END = prefix + "POINT_EDIT_END";
export const POINT_CHANGE = prefix + "POINT_CHANGE";
export const SUSTAIN_CHANGE = prefix + "SUSTAIN_CHANGE";
export const SUSTAIN_EDIT_START = prefix + "SUSTAIN_EDIT_START";
export const SUSTAIN_EDIT_END = prefix + "SUSTAIN_EDIT_END";
export const MODE_CHANGE = prefix + "MODE_CHANGE";
export const DURATION_CHANGE = prefix + "DURATION_CHANGE";
export const BLUR = prefix + "BLUR";
