export const patchDefault = {
    "mode": "portamento"
};

export const playstateDefault = {
    enabled: false,
    chords: [],
    activeKeys: {},
    amount: 0
};
